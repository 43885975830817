import branchTypes from './branchTypes'

const routePage = {
  BNN: {
    [branchTypes.BRANCH_TYPE_DEPOSIT]: {
      ProductDetail: {
        nextPage: 'CustomerAlsoBought',
      },
      CustomerAlsoBought: {
        nextPage: 'Cart',
        backPage: 'ProductDetail',
      },
      Cart: {
        backPage: 'CustomerAlsoBought',
      },
    },
    [branchTypes.BRANCH_TYPE_FULL_PAYMENT]: {
      ProductDetail: {
        nextPage: 'CustomerAlsoBought',
      },
      CustomerAlsoBought: {
        nextPage: 'Cart',
        backPage: 'ProductDetail',
      },
      Cart: {
        backPage: 'CustomerAlsoBought',
      },
    },
  },
  STUDIO7: {
    [branchTypes.BRANCH_TYPE_DEPOSIT]: {
      ProductDetail: {
        nextPage: 'CustomerAlsoBought',
      },
      CustomerAlsoBought: {
        nextPage: 'Cart',
        backPage: 'ProductDetail',
      },
      Cart: {
        backPage: 'CustomerAlsoBought',
      },
    },
    [branchTypes.BRANCH_TYPE_FULL_PAYMENT]: {
      ProductDetail: {
        nextPage: 'CustomerAlsoBought',
      },
      CustomerAlsoBought: {
        nextPage: 'Cart',
        backPage: 'ProductDetail',
      },
      Cart: {
        backPage: 'CustomerAlsoBought',
      },
    },
  },
}

export default routePage
