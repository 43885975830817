<template>
  <div v-if="model" class="full-page customer-also-bought">
    <v-container>
      <ProductCard />
      <template v-if="isBnn">
        <v-row
          v-if="hasAdditionalProduct"
          v-bind="{ justify: 'center' }"
        >
          <div class="title-customer-also-bought">
            <h1 class="text-center">
              อุปกรณ์เสริมราคาพิเศษเมื่อซื้อคู่กัน
            </h1>
          </div>
        </v-row>
        <template v-if="additionalProduct">
          <v-row
            v-for="(item, index) in additionalProduct"
            :key="index"
          >
            <v-col>
              <v-row v-bind="{ justify: 'start' }">
                <v-col>
                  <h2>{{ item.group }}</h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <template v-if="item.products.length > 0">
                    <AdditionProductSwiper
                      @click="onClickAdditionProduct"
                      :data="item.products"
                    />
                  </template>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
      </template>

      <template v-else>
        <template v-if="additionalProduct">
          <PDPProductAlsoBought
            v-for="(item, index) in additionalProduct"
            :key="index"
            :keyProducts="`${index}`"
            :title="item.group"
            :items="item.products"
            isBundle
            @click="checkSummary"
          />
        </template>
      </template>
      <div class="footer-margin"></div>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ProductCard from '@/components/bnn/ProductCard'
import AdditionProductSwiper from '@/components/bnn/AdditionProductSwiper'
import cartSummary from '@/mixins/cartSummary'

export default {
  mixins: [cartSummary],

  computed: {
    ...mapState({
      isLoading: state => state.model.isLoading,
      isLoaded: state => state.model.isLoaded,
      additionalProduct: state => state.model.additionalProduct,
      model: state => state.model.selectModel,
      selects: state => state.bundle.selects,
    }),

    hasAdditionalProduct() {
      return this.additionalProduct?.length > 0
    },
  },

  data() {
    return {}
  },

  mounted() {
    if (!this.hasAdditionalProduct) {
      this.$router
        .push({
          name: 'Cart',
          params: {
            slug: this.slug,
          },
        })
        .catch(() => true)
    }

    if (!this.model) {
      this.$router
        .push({
          name: 'ProductDetail',
          params: {
            slug: this.$route.params?.slug,
          },
        })
        .catch(() => true)
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    this.checkSummary()
  },

  methods: {
    onClickAdditionProduct(item) {
      this.$store.dispatch('bundle/toggle', item)
      this.$forceUpdate()
      this.checkSummary()
    },
  },

  components: {
    ProductCard,
    AdditionProductSwiper,
    PDPProductAlsoBought: () =>
      import('@/components/studio7/PDPProductAlsoBought.vue'),
  },
}
</script>

<style lang="stylus" scoped>

.customer-also-bought {
  background: $color-bnn-gray-light-2 0% 0% no-repeat padding-box
  height 100%
  width 100%
  margin-top: 75px
}

.customer-also-bought {
    background: #fff
    width 100%
    height 100%
  }

.title-customer-also-bought {
    padding: 0 0 2.5rem 0

    @media small {
      font-size: 0.85rem
    }
}

.footer-margin
    margin-bottom: 60px;

@media screen and (max-width: 960px)
  .footer-margin
    margin-bottom: 60px;
</style>
