<template>
  <div class="product-card">
    <!-- 
      TODO: Remove section
      <div class="preview">
      <img :src="selectSKU.image_url" class="img" />
      <div class="product-main-layout">
        <div class="desc-main">
          <div class="product-name">{{ selectSKU.name }}</div>
          <div class="product-sku">SKU: {{ selectSKU.sku }}</div>
        </div>
        <div class="price-main">
          <template v-if="isDeposit">
            <div class="price-text-2">
              <span v-if="isDeposit">
                มัดจำ
              </span>
              ฿{{ productPriceDeposit | currency }}.-
            </div>
          </template>
          <div
            class="price-text margin-top-0-5"
            :class="customClassTextPriceFull"
          >
            <span v-if="isDeposit">
              ราคาเต็ม
            </span>
            ฿{{ selectSKU.price | currency }}.-
          </div>
        </div>
      </div>
    </div>

    <div
      class="preview"
      v-for="(item, index) in bundleList"
      :key="index"
    >
      <template v-if="item.isFreeBie">
        <template v-if="showFreeBie">
          <img :src="item.imageUrl" class="img" />
          <div class="desc">
            <div class="product-name">{{ item.name }}</div>
            <div class="product-sku">SKU: {{ item.sku }}</div>
          </div>
          <div class="price">
            <div v-if="+item.price_srp === 0" class="price-text">
              ฟรี
            </div>
            <div v-else class="price-text">
              <template v-if="delivery">
                ฿{{ item.price_ship | currency }}.-
              </template>
              <template v-else>
                ฿{{ item.price_store | currency }}.-
              </template>
            </div>
          </div>
        </template>
      </template>
      <template v-else>
        <img :src="item.imageUrl" class="img" />
        <div class="desc">
          <div class="product-name">{{ item.name }}</div>
          <div class="product-sku">SKU: {{ item.sku }}</div>
        </div>
        <div class="price">
          <div v-if="+item.price_srp === 0" class="price-text">
            ฟรี
          </div>
          <div v-else class="price-text">
            <template v-if="delivery">
              ฿{{ item.price_ship | currency }}.-
            </template>
            <template v-else>
              ฿{{ item.price_store | currency }}.-
            </template>
          </div>
        </div>
      </template>
    </div>

    <div
      class="preview"
      v-for="(item, index) in additionalList"
      :key="index"
    >
      <img :src="item.imageUrl" class="img" />
      <div class="desc">
        <div class="product-name">{{ item.name }}</div>
        <div class="product-sku">SKU: {{ item.sku }}</div>
      </div>
      <div class="price">
        <div v-if="delivery" class="price-text">
          ฿{{ item.price_ship | currency }}.-
        </div>
        <div v-else class="price-text">
          ฿{{ item.price_store | currency }}.-
        </div>
      </div>
    </div> -->

    <SummaryCart :is-show-detail-price="false" />

    <!-- <div class="summary">
      <div class="count-number">
        {{ countOrder }} สินค้าในรายการสั่งซื้อ
      </div>
      <div class="price-text">
        <span>ยอดรวมการสั่งซื้อ</span>
        <span class="price"> ฿{{ priceSummary | currency }}.- </span>
      </div>
    </div>

    <hr class="my-3 gray" />

    <div class="action-layout">
      <div class="action">
        <Button
          @click="goBack()"
          block
          depressed
          color="color-bnn-gray-light-2"
          title="ย้อนกลับ"
        />
        <Button
          @click="goToCart()"
          block
          depressed
          color="color-bnn"
          title="ถัดไป"
        />
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import SummaryCart from '@/components/bnn/SummaryCart.vue'
import branchTypes from '@/config/branchTypes'
import config from '@/config/index'
const { showFreeBie } = config

export default {
  components: {
    SummaryCart,
  },

  computed: {
    ...mapState({
      isLoading: state => state.model.isLoading,
      isLoaded: state => state.model.isLoaded,
      selectSKU: state => state.model.selectSKU,
      cartSummaryData: state => state.cart.cartSummaryData,
      branchType: state => state.branchStore.branchType,
      delivery: state => state.cart.delivery,
    }),

    ...mapGetters({
      cartItems: 'cart/cartItems',
      // priceSummary: 'cart/priceSummary',
      isSoldOut: 'model/getSoldOut',
    }),

    customClassTextPriceFull() {
      return {
        'drop-full-payment': this.isDeposit,
      }
    },

    customClassMainProductLayout() {
      return {
        'justify-between': this.isDeposit,
      }
    },

    isDeposit() {
      return this.branchType === this.branchTypes.BRANCH_TYPE_DEPOSIT
    },

    productPriceDeposit() {
      return this.cartSummaryData?.product_price_deposit ?? 0
    },

    bundleList() {
      return this.cartItems.filter(item => item.type === 'bundle')
    },

    countOrder() {
      return (
        this.cartItems.filter(item => item.type === 'bundle')
          ?.length +
        this.cartItems.filter(
          item => item.type === 'additional_product',
        )?.length +
        (this.selectSKU ? 1 : 0)
      )
    },

    additionalList() {
      return this.cartItems.filter(
        item => item.type === 'additional_product',
      )
    },
  },

  data() {
    return {
      showFreeBie,
      branchTypes,
    }
  },

  methods: {
    goBack() {
      this.$router
        .push({
          name: 'ProductDetail',
          params: {
            slug: this.$route.params?.slug,
          },
        })
        .catch(() => true)
    },

    goToCart() {
      this.$router
        .push({
          name: 'Cart',
          params: {
            slug: this.$route.params?.slug,
          },
        })
        .catch(() => true)
    },
  },
}
</script>

<style lang="stylus" scoped>

.product-main-layout
  display: flex
  justify-content: space-between
  width 100%
  .desc-main
    width 50%
    display: flex
    flex-direction: column
    justify-content: flex-start
    height 100%
    @media small
      justify-content: flex-start
      width 70%
    @media tablet
      justify-content: flex-start
    .product-name
                color: $color-bnn-gray-medium
                font-weight: bold
                margin-top: 0.5rem
    .product-sku
                color: $color-bnn-gray-medium-2
  .price-main
    width 50%
    display: flex
    flex-direction: column
    justify-content: flex-start
    height 100%
    text-align: right
    @media small
      width 30%
    @media tablet
      justify-content: space-between
    .price-text, .price-text-2
          font-size: 1rem
          // color:  $color-text-price
          text-align: right
          @media screen and (max-width: 768px)
            font-size: 0.9rem
            margin-top: 0.5rem
    .price-text-2
          //  color: $color-bnn !important


.product-card
    // background: white 0% 0% no-repeat padding-box;
    border-radius: 8px;
    display: flex
    justify-content: space-between
    flex: 1 1
    // padding: 1.25rem 3rem
    // margin-top: 40px
    flex-direction: column
    @media screen and (max-width: 768px)
      padding: 1rem 0.5rem 0 0.5rem
      font-size: 0.85rem
    .preview
        width: 100%
        display: flex
        justify-content: flex-start
        gap: 16px 16px
        margin: 1rem 0
        .img
            width: 75px
            height: 75px
            min-width: 75px
            max-width: 75px
            min-height: 75px
            max-height: 75px
            // height: 100%
            object-fit: contain
        .desc
            display: flex
            flex-direction: column
            justify-content: flex-start
            width: 100%
            .product-name
                color: $color-bnn-gray-medium
                font-weight: bold
                margin-top: 0.5rem
            .product-sku
                color: $color-bnn-gray-medium-2

    .price
        width: 100%
        display: flex
        justify-content: flex-start
        flex-direction: column
        align-items: flex-end
        .price-text
            font-size: 1rem
            color:  $color-text-price
            text-align: right
            @media screen and (max-width: 768px)
              font-size: 0.9rem
              margin-top: 0.5rem
.action-layout
  width: 100%
  display: flex
  justify-content: flex-end
  margin: 1.5rem 0
  .action
        width 50%
        display: grid
        gap: 16px
        grid-template-columns: repeat(2, 1fr)

.summary
    display: flex
    justify-content: space-between
    margin: 1rem 0
    .count-number
      width: 50%
      color: $color-bnn-gray-medium-2
    .price-text
      width 50%
      display: flex
      flex-wrap: nowrap
      flex-direction: row
      justify-content: flex-end
      .price
        color: $color-bnn-red-2
        font-weight: bold
        width auto
        margin-left: 0.2rem

.justify-between
    justify-content: space-between !important

.margin-top-0-5
    margin-top: 0.5rem

.drop-full-payment
  font-size: 0.875rem !important
  color: $color-bnn-gray-medium-2
</style>
