export const INSTALLMENT = Object.freeze({
  MAX: 20,
})

export const SHIPPING_DATE = Object.freeze({
  FIRST_LOT: '2024-09-20',
  SECOND_LOT: '2024-09-27',
  THIRD_LOT: '2024-10-04',
})

export const CONFIG_META = Object.freeze({
  PATH: ['/static/page/offer-prebook-iphone-16-series'],
})

export default {
  showFreeBie: true,
}
