<template>
  <div>
    <swiper
      class="swiper swiper-addition-product"
      :options="swiperOption"
    >
      <swiper-slide v-for="(item, index) in data" :key="index + 1">
        <AdditionCard
          @click="$emit('click', item)"
          :data="item"
          :select="isSelected(item)"
        />
      </swiper-slide>
      <div
        class="swiper-pagination swiper-pagination-addition-product"
        slot="pagination"
      ></div>
      <div class="swiper-button-prev" slot="button-prev">
        <span class="angle-icon">
          <font-awesome-icon size="lg" icon="angle-left" />
        </span>
      </div>
      <div class="swiper-button-next" slot="button-next">
        <span class="angle-icon">
          <font-awesome-icon size="lg" icon="angle-right" />
        </span>
      </div>
    </swiper>
  </div>
</template>

<script>
import BREAKPOINT from '@/config/breakpoint'
import AdditionCard from '@/components/bnn/AdditionCard'

export default {
  name: 'swiper-example-loop-group',
  title: 'Loop mode with multiple slides per group',

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    AdditionCard,
  },

  data() {
    return {
      swiperOption: {
        // on: {
        //   init: function(swiper) {
        //     console.log('swiper initialized', swiper.slideTo(0))
        //     swiper.slideTo(0)
        //   },
        //   resize: function() {
        //     console.log('resize')
        //   },
        // },
        // initialSlide: 0,
        // paginationClickable: true,
        slidesPerView: window.innerWidth <= BREAKPOINT.TABLET ? 1 : 4,
        spaceBetween: 10,
        slidesPerGroup:
          window.innerWidth <= BREAKPOINT.TABLET ? 1 : 4,
        loop: false,
        // loopFillGroupWithBlank: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },

  methods: {
    unit(item) {
      return this.$store.getters['bundle/countBySKU'](item)
    },

    isSelected(item) {
      return this.unit(item) > 0
    },
  },
}
</script>

<style lang="stylus" scoped>

.angle-icon {
    width: 36px
    height: 36px
    background: $color-bnn-gray-medium-2
    border-radius: 50px
    display: flex
    justify-content: center
    align-items: center
}

.swiper {
  // height: 350px;
  width: 100%;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 1rem;
    background: white
  }
}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
  left 0 !important
}

.swiper-button-next, .swiper-rtl .swiper-button-prev {
  right 0 !important
}

.swiper-button-next::after, .swiper-button-prev::after {
    content: "" !important
}

.swiper-button-next, .swiper-button-prev {
    color: white !important
    width auto !important
    margin-top: -35px
}

::v-deep .swiper-pagination-bullet-active {
  width: 24px !important
  background: $color-bnn-gray-medium 0% 0% no-repeat padding-box !important;
  border-radius: 2px !important;
}

::v-deep .swiper-pagination-bullet {
  width: 16px
  background: $color-bnn-gray-light 0% 0% no-repeat padding-box;
  border-radius: 2px;
  opacity 1
}

.swiper-addition-product {
  padding-bottom: 1.5rem;
}

.swiper-pagination-addition-product {
  bottom: 0
}

.swiper-slide-active,.swiper-slide-next  {
  @media small {
    width: calc(100% - 120px) !important
  }

  @media tablet {
    width: calc(100% - 120px) !important
  }

}
</style>
