<template>
  <div v-if="showSummary" class="summary-cart" :class="customClass">
    <v-container>
      <!-- <div v-if="!isShowDetailPrice" class="summary"> -->
      <!-- <div class="count-number">
          {{ countOrder }} สินค้าในรายการสั่งซื้อ
        </div>
        <div class="price-text">
          <span v-if="isDeposit">ยอดรวมชำระมัดจำ</span>
          <span v-else>ยอดรวมชำระเต็มจำนวน</span>
          <span class="price">
            ฿{{ netPriceSummary | currency }} .-
          </span>
        </div> -->
      <!-- </div> -->
      <div v-if="isShowDetailPrice" class="summary-cart-price-detail">
        <!-- <div class="count-number">
          {{ countOrder }} สินค้าในรายการสั่งซื้อ
        </div>
        <div class="price-text">
          <span>ยอดรวมการสั่งซื้อ</span>
          <span class="price">
            ฿{{ netPriceSummary | currency }} .-
          </span>
        </div> -->
        <!-- <div
          v-if="couponDiscount > 0"
          class="price-summary-cart-3-lines"
        >
          <div>
            <h4>ส่วนลด</h4>
          </div>
          <div class="text-right text-discount">
            <h4>-฿{{ couponDiscount | currency2 }} .-</h4>
          </div>
        </div>

        <div
          v-if="onTopDiscount > 0"
          class="price-summary-cart-3-lines"
        >
          <div>
            <h4>ส่วนลดเพิ่มเติม</h4>
          </div>
          <div class="text-right text-discount">
            <h4>-฿{{ onTopDiscount | currency2 }} .-</h4>
          </div>
        </div> -->

        <div class="price-summary-cart-3-lines">
          <div>
            <h4 v-if="isDeposit">ยอดรวมชำระมัดจำ</h4>
            <h4 v-else>ยอดรวมชำระเต็มจำนวน</h4>
          </div>
          <div class="text-right price-summary-text">
            <h4>฿{{ netPriceSummary | currency2 }} .-</h4>
          </div>
        </div>
      </div>

      <!-- <hr v-if="isShowAction" class="my-3 gray" /> -->

      <div v-if="isShowAction" class="action-layout">
        <div class="action">
          <div class="btn-back">
            <v-btn @click="goBack()" text class="btn-link">
              <v-icon>
                mdi-chevron-left
              </v-icon>
              กลับสู่หน้าสินค้า
            </v-btn>
          </div>
          <div class="btn-next">
            <Button
              @click="goToCart()"
              width="fit-content"
              depressed
              color="color-bnn"
              title="ดำเนินการสั่งซื้อ"
            />
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import Button from '@/components/bnn/Button.vue'
import routePage from '@/config/routePage'
import branchTypes from '@/config/branchTypes'
import config from '@/config/index'
const { showFreeBie } = config
import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState({
      isLoading: state => state.model.isLoading,
      isLoaded: state => state.model.isLoaded,
      cartSummaryData: state => state.cart.cartSummaryData,
      selectSKU: state => state.model.selectSKU,
      branchType: state => state.branchStore.branchType,
      showSummary: state => state.cart.showSummary,
    }),

    ...mapGetters({
      cartItems: 'cart/cartItems',
      priceSummary: 'cart/priceSummary',
    }),

    isDeposit() {
      return this.branchType === this.branchTypes.BRANCH_TYPE_DEPOSIT
    },

    summaryAmount() {
      if (this.isDeposit) {
        return this.selectSKU?.price_deposit ?? 0
      }
      return this.priceSummary
    },

    countOrder() {
      const bundles = this.cartItems.filter(
        item => item.type === 'bundle',
      )
      if (!this.showFreeBie) {
        return (
          bundles?.filter(i => !i.isFreeBie)?.length +
          this.cartItems.filter(
            item => item.type === 'additional_product',
          )?.length +
          (this.selectSKU ? 1 : 0)
        )
      }
      return (
        bundles?.length +
        this.cartItems.filter(
          item => item.type === 'additional_product',
        )?.length +
        (this.selectSKU ? 1 : 0)
      )
    },

    beforeDiscount() {
      return this.cartSummaryData?.before_discount ?? 0
    },

    couponDiscount() {
      return this.cartSummaryData?.coupon_discount ?? 0
    },

    afterCouponDiscount() {
      return this.cartSummaryData?.after_coupon_discount ?? 0
    },

    onTopDiscount() {
      return this.cartSummaryData?.on_top_discount ?? 0
    },

    summaryDiscount() {
      return this.cartSummaryData?.summary_discount ?? 0
    },

    onTopDiscountPercent() {
      return this.cartSummaryData?.on_top_discount_percent ?? 0
    },

    netPriceSummary() {
      return this.cartSummaryData?.total_price ?? 0
    },

    customClass() {
      return {
        'detail-content': this.isShowDetailPrice,
      }
    },
  },

  data() {
    return {
      branchTypes,
      showFreeBie,
    }
  },

  props: {
    isShowAction: {
      type: Boolean,
      default: true,
    },

    isShowDetailPrice: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    Button,
  },

  methods: {
    goBack() {
      // this.$router
      //   .push({
      //     name: 'ProductDetail',
      //     params: {
      //       slug: this.$route.params?.slug,
      //     },
      //   })
      //   .catch(() => true)
      this.$router
        .push({
          name:
            routePage?.[process.env.VUE_APP_CAMPAIGN_TYPE]?.[
              this.branchType
            ]?.[this.$route.name]?.backPage,
          params: { slug: this.$route.params?.slug },
        })
        .catch(() => true)
    },

    goToCart() {
      this.$router
        .push({
          name: 'Cart',
          params: {
            slug: this.$route.params?.slug,
          },
        })
        .catch(() => true)
    },
  },
}
</script>

<style lang="stylus" scoped>

.summary-cart
  height: auto;
  width: 100%
  bottom: 0
  left: 0
  position: fixed
  z-index 50
  background-color: $color-dapp-gray-4;
  box-shadow: 0 0.5px 0 0 $color-gray83 inset;
  // background: #fff
  // box-shadow: 1px 1px 2px 1px $color-gray-200;

.detail-content
  display: flex
  align-items: center
  height: 110px !important
  @media small
    margin-bottom: 150px

.action-layout
  width: 100%
  display: flex
  justify-content: flex-end
  padding: 20px 12px
  .action
        width 100%
        display: flex
        flex-direction: row
        gap: 16px
        grid-template-columns: repeat(2, 1fr)
        align-items: center
        justify-content: space-between
        @media screen and (max-width: 960px)
          width 100%
          grid-template-columns: repeat(2, 1fr)
        .btn-back
          width: 50%
          @media screen and (max-width: 960px)
            width: 100%

        .btn-next
           width: fit-content

.price
        width: 100%
        display: flex
        justify-content: flex-start
        flex-direction: column
        align-items: flex-end
        .price-text
            font-size: 1.15rem
            color:  $color-bnn-red-2
            text-align: right
            @media screen and (max-width: 960px)
               font-size: 0.875rem

.summary
    display: flex
    justify-content: space-between
    margin: 1rem 0
    font-size: 1.15rem
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    .count-number
      width: 50%
      color: $color-bnn-gray-medium-2
      @media screen and (max-width: 960px)
         font-size: 0.875rem
    .price-text
      width 50%
      display: flex
      flex-wrap: nowrap
      flex-direction: row
      justify-content: flex-end
      @media screen and (max-width: 960px)
         font-size: 0.875rem
      .price
        color: $color-text-price
        font-weight: bold
        width auto
        margin-left: 0.2rem

.summary-cart-price-detail
    display: flex
    flex-direction: column
    // justify-content: space-between
    margin: 1rem 0
    font-size: 1.15rem

.price-summary-text
    color:  $color-text-price

.v-btn-back
  font-weight: bold !important
  font-size: 0.875rem !important
  padding: 18.5px 0 !important
  border-color: $color-secondary !important

.v-text-back
  color: $color-secondary-text

.price-summary-cart-3-lines
  display: flex
  justify-content: space-between
  align-items: center
  font-size: 1 rem

.text-discount
  color $color-bnn-red

.btn-link {
    padding 0 !important
    color: $color-bnn;
    cursor pointer

    &:hover:before {
        background: transparent !important
    }

    .v-icon {
        color: $color-bnn
    }
}
</style>
