<template>
  <div :class="customClass">
    <img :src="data.image_url" />
    <div class="px-8 text-left bundle-name">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on"> {{ data.name }}</span>
        </template>
        <span> {{ data.name }}</span>
      </v-tooltip>
    </div>
    <div class="bundle-price">
      <!-- <div v-if="delivery">฿{{ data.price_ship | currency }} .-</div>
      <div v-else>฿{{ data.price_store | currency }} .-</div>
      <div
        v-if="delivery && data.price_ship !== data.price_srp"
        class="text-line-through normal-price"
      >
        ฿{{ item.price_srp | currency }} .-
      </div>
      <div
        v-else-if="!delivery && data.price_store !== data.price_srp"
        class="text-line-through normal-price"
      >
        ฿{{ item.price_srp | currency }} .-
      </div> -->
      <div
        v-if="
          data.price_srp !== data.price_deposit &&
            data.price_deposit > 0
        "
        class="deposit-price"
      >
        ราคามัดจำ ฿{{ data.price_deposit | currency }}.-
      </div>
      <div>
        ราคาเต็ม
        <template v-if="delivery">
          ฿{{ data.price_ship | currency }}.-
        </template>
        <template v-else>
          ฿{{ data.price_store | currency }}.-
        </template>
      </div>

      <div
        v-if="delivery && data.price_ship !== data.price_srp"
        class="text-line-through normal-price"
      >
        ฿{{ data.price_srp | currency }} .-
      </div>
      <div
        v-else-if="!delivery && data.price_store !== data.price_srp"
        class="text-line-through normal-price"
      >
        ฿{{ data.price_srp | currency }} .-
      </div>
      <div v-else class="normal-price"></div>
    </div>

    <div class="px-8">
      <Button
        v-if="!select"
        @clickStop="$emit('click')"
        :disabled="!data.active"
        block
        depressed
        color="color-bnn"
        height="4px"
        title="เพิ่ม"
      />
      <v-btn
        v-else
        @click.stop="$emit('click')"
        block
        :disabled="!data.active"
        outlined
        class="v-btn-cancel-item"
        height="4px"
      >
        <span class="v-text-cancel-item">
          นำออก
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
// import ButtonAddToCart from '@/components/bnn/ButtonAddToCart'
import { mapState } from 'vuex'
import Button from '@/components/bnn/Button'
export default {
  components: {
    Button,
    // ButtonAddToCart,
  },

  computed: {
    ...mapState({
      delivery: state => state.cart.delivery,
    }),

    customClass() {
      if (!this.data.active) {
        return 'bundle-card disabled'
      } else if (this.select) {
        return 'bundle-card'
      }
      return 'bundle-card'
    },
  },

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    select: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="stylus" scoped>

.bundle-card
    position relative
    // border: 1px solid $color-bnn-gray-light
    background white
    border-radius: 8px;
    display: flex
    justify-content: center
    flex-wrap: wrap
    gap: 16px 0;
    // grid-template-columns: repeat(1, 1fr);
    height: 360px;
    padding 1rem 0
    &:hover
        cursor pointer
    img {
        max-width:150px;
        max-height:150px;
        width: 100%
        height: 100%
        object-fit: contain
    }
    div {
        width 100%
    }

.bundle-name {
   display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 50px
    padding-top: 1rem;
}

.bundle-card.disabled
    opacity 0.4
    cursor: not-allowed

.bundle-price {
    font-size: 1.2rem
    font-weight: bold
    color: $color-bnn-red-2
    margin-top: -1rem
    padding-left: 2rem
    text-align: left
    bottom: 0
    left: 0
}

.bundle-card.select
    border: 1px solid $color-bnn-gray-medium;

.v-btn-cancel-item
  font-weight: bold !important
  font-size: 0.875rem !important
  padding: 18.5px 0 !important
  border-color: $color-bnn !important
  background-color: $color-fourth !important

.v-text-cancel-item
  color: $color-secondary-text

.normal-price
  color: $color-bnn-gray-medium-2
  font-size: 0.85rem
  text-align: left
  height: 22px

.deposit-price
  color: $color-bnn-gray-medium-2
</style>
